<script lang="tsx">
import { defineComponentBaseSideDrawer } from '@core/app/components/base/BaseSideDrawer.vue'

type Sizes = 'sm'

export default defineComponentBaseSideDrawer<Sizes>()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseSideDrawer" as *;

@include drawer {
    @include set-padding(1rem, 1.5rem, 1.5rem, 1.5rem);
    @include set-padding-after-breakpoint('md', 2rem, 2rem, 2rem, 2rem);

    overflow-x: hidden;

    background-color: $sim-c-white;
}

@include drawer__header {
    background-color: rgba(#fff, 0.85);
    backdrop-filter: blur(10px);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

@include drawer--size('sm') {
    @include drawer {
        width: min(28rem, 100%);
    }
}

</style>
