<script lang="tsx">
import type { SlotsType, Ref } from 'vue'
import { Teleport, Transition } from 'vue'
import type { ComponentOverrideOptions } from '@core-types/components'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { BaseFullscreenBackground } from '#components'
import { type BaseModalProvide, SymbolBaseModal } from '@core/app/composables/components'
import type { PopupOptions } from '../../composables/useManagePopupOpening'

export type BaseSideDrawerProps<Sizes extends string> = {
    modelValue?: boolean
    ariaLabel?: string
    ariaLabelledby?: string
    ariaDescribedby?: string
    alert?: boolean

    size?: Sizes

    /**
     * @default 'right'
     */
    side?: 'left' | 'right'
    autoCloseOn?: PopupOptions['autoCloseOn']
}

type BaseSideDrawerSlots<T> = {
    default: { close: () => void }
    header: { close: () => void }
}

type ComponentOptions = {}

export function defineComponentBaseSideDrawer<Sizes extends string = 'md'>(options?: ComponentOverrideOptions<ComponentOptions, BaseSideDrawerProps<Sizes>, BaseSideDrawerSlots<Sizes>>) {
    return defineComponent(
        (props: BaseSideDrawerProps<Sizes>, ctx) => {

            const parentScope = getScopeIdAttr()

            const isOpen = computed<boolean>({
                get() {
                    return props.modelValue ?? false
                },
                set(val: boolean) {
                    ctx.emit('update:modelValue', val)
                },
            })

            // the content of the modal
            const sidebarContent: Ref<HTMLElement | null> = ref(null)

            // the element that was focused when the modal was opened (the one that opened the modal)
            let prevActiveElement: HTMLElement | null = null


            // focus trap library initialization
            const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(sidebarContent, {
                escapeDeactivates: false,   // handled by custom useManagePopupOpening composable
                allowOutsideClick: true,
                setReturnFocus: () => {
                    // return the element to set the focus to, or `false` (not to focus anything)
                    return prevActiveElement ?? false
                },
                fallbackFocus: () => document.body,
            })

            // page properties store for global body scroll lock
            const { tryToUnlockScroll } = usePopupsStore()

            /**
             * A callback function that is called after the sidebar open animation is finished.
             * Activates the focus trap. (because there can be problems with focusing elements that are being animated)
             */
            function afterEnter() {
                try {
                    activateFocusTrap()  // activate the focus trap
                } catch (e) {
                    /*
                      When there are no focusable elements inside the sidebar, the focus trap throws an error.
                     */
                }
            }

            /**
             * A callback function that is called after the sidebar close animation is finished.
             * Unlocks the scroll lock of the body. (so that the scroll bar only appears after the close animation is finished,
             * to prevent a visual shift)
             */
            function afterLeave() {
                tryToUnlockScroll()
            }

            /**
             * A function used to save the currently open modal to the store & clean it up after it's closed.
             * @private
             */
            function closeModal() {
                isOpen.value = false
            }

            // WATCHERS & LIFE CYCLE HOOKS ------------------------------------------------

            useManagePopupOpening(isOpen, {
                closeCallback: closeModal,
                autoUnlockScroll: false,
                autoCloseOn: props.autoCloseOn,
            }, {
                onOpen: () => {
                    // save the previously focused element, before the modal was opened (the one that opened the modal)
                    prevActiveElement = document.activeElement as HTMLElement
                    // emit opened event
                    ctx.emit('opened')
                },
                onClose: () => {
                    // deactivate the focus trap when the modal is closed
                    deactivateFocusTrap()
                    // emit closed event
                    ctx.emit('closed')
                },
            })

            ctx.expose({
                close: closeModal,
            })

            provide<BaseModalProvide>(SymbolBaseModal, {
                close: closeModal,
            })

            return () => (
                <Teleport to="#teleports">
                    <div class={['sim-side-drawer', {
                        'sim-side-drawer--open': isOpen.value,
                        'sim-side-drawer--left': props.side === 'left',
                        [`sim-side-drawer--s-${props.size}`]: props.size,
                    }]}
                    >
                        <BaseFullscreenBackground {...{
                            'modelValue': isOpen.value,
                            'onUpdate:modelValue': (val: boolean) => isOpen.value = val,
                        }} />

                        <Transition
                            appear
                            onAfterEnter={afterEnter}
                            onAfterLeave={afterLeave}
                        >
                            {isOpen.value && (
                                <div {...{
                                    'ref': sidebarContent,
                                    ...ctx.attrs,
                                    ...parentScope,
                                    'class': ['sim-side-drawer__content', ctx.attrs.class],
                                    'aria-label': props.ariaLabel,
                                    'aria-labelledby': props.ariaLabelledby,
                                    'aria-describedby': props.ariaDescribedby,
                                    'aria-modal': isOpen.value ? true : undefined,
                                    'role': props.alert ? 'alertdialog' : 'dialog',
                                }}
                                >
                                    {(ctx.slots.header !== undefined || options?.slots?.header) &&
                                            <div class="sim-side-drawer__header">
                                                {renderSlot(ctx.slots.header, options?.slots?.header, {
                                                    close: closeModal,
                                                })}
                                            </div>
                                    }

                                    {renderSlot(ctx.slots.default, options?.slots?.default, {
                                        close: () => closeModal(),
                                    })}
                                </div>
                            )}
                        </Transition>
                    </div>
                </Teleport>
            )
        },
        {
            inheritAttrs: false,
            props: {
                modelValue: {
                    type: Boolean as PropType<BaseSideDrawerProps<Sizes>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseSideDrawerProps<Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaLabelledby: {
                    type: String as PropType<BaseSideDrawerProps<Sizes>['ariaLabelledby']>,
                    default: options?.props?.ariaLabelledby?.default,
                    required: options?.props?.ariaLabelledby?.required ?? false,
                },
                ariaDescribedby: {
                    type: String as PropType<BaseSideDrawerProps<Sizes>['ariaDescribedby']>,
                    default: options?.props?.ariaDescribedby?.default,
                    required: options?.props?.ariaDescribedby?.required ?? false,
                },
                alert: {
                    type: Boolean as PropType<BaseSideDrawerProps<Sizes>['alert']>,
                    default: options?.props?.alert?.default,
                    required: options?.props?.alert?.required ?? false,
                },

                size: {
                    // @ts-ignore
                    type: String as PropType<BaseSideDrawerProps<Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },

                side: {
                    type: String as PropType<BaseSideDrawerProps<Sizes>['side']>,
                    default: options?.props?.side?.default ?? 'right',
                    required: options?.props?.side?.required ?? false,
                },
                autoCloseOn: {
                    type: String as PropType<BaseSideDrawerProps<Sizes>['autoCloseOn']>,
                    default: options?.props?.autoCloseOn?.default,
                    required: options?.props?.autoCloseOn?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseSideDrawerSlots<Sizes>>,
            emits: {
                'update:modelValue': (val: boolean) => true,
                'opened': () => true,
                'closed': () => true,
            },
        }
    )
}

export default defineComponentBaseSideDrawer()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseSideDrawer.scss" as *;

@include drawer {
    @include set-padding(1rem, 1.5rem, 1.5rem, 1.5rem);
    @include set-padding-after-breakpoint('md', 2rem, 2rem, 2rem, 2rem);

    background-color: white;
}

@include drawer__header {
    background-color: rgba(#fff, 0.85);
    backdrop-filter: blur(10px);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

</style>
